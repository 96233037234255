import { PaginationMetaData } from "@core/interfaces/pagination.interface";
import { createAction, props } from "@ngrx/store";

export enum BoxTypeActionsTypes {
  getBoxType = "[BoxType] get BoxType",
  successGetBoxType = "[BoxType] Success get BoxType",
  errorGetBoxType = "[BoxType] Error get BoxType",

  createBoxType = "[BoxType] Create BoxType",
  successCreateBoxType = "[BoxType] Success Create BoxType",
  errorCreateBoxType = "[BoxType] Error Create BoxType",
}

export const getBoxType = createAction(
  BoxTypeActionsTypes.getBoxType,
  props<{ filters: string }>()
);
export const successGetBoxType = createAction(
  BoxTypeActionsTypes.successGetBoxType,
  props<{ boxType: Array<any>; pagination: PaginationMetaData }>()
);
export const errorGetBoxType = createAction(
  BoxTypeActionsTypes.errorGetBoxType,
  props<{ payload: any }>()
);

export const createBoxType = createAction(
  BoxTypeActionsTypes.createBoxType,
  props<{ boxType: any }>()
);
export const successCreateBoxType = createAction(
  BoxTypeActionsTypes.successCreateBoxType,
  props<{ boxType: any }>()
);
export const errorCreateBoxType = createAction(
  BoxTypeActionsTypes.errorCreateBoxType,
  props<{ payload: any }>()
);
