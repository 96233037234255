import { createAction, props } from '@ngrx/store';
import { Dimension } from '../../interfaces/dimension.interface';
import { PaginationMetaData } from '@core/interfaces/pagination.interface';

export enum DimensionActionsTypes {
    getDimension= '[Dimension] get dimension',
    successGetDimension= '[Dimension] Success get dimension',
    errorGetDimension='[Dimension] Error get Dimension',

    createDimension= '[Dimension] Create dimension',
    successCreateDimension= '[Dimension] Success Create dimension',
    errorCreateDimension='[Dimension] Error Create Dimension',

    updateDimension= '[Dimension] Update dimension',
    successUpdateDimension= '[Dimension] Success Update dimension',
    errorUpdateDimension='[Dimension] Error Update Dimension',
}


export const getDimension = createAction(DimensionActionsTypes.getDimension, props<{ filters: string }>());
export const successGetDimension = createAction(DimensionActionsTypes.successGetDimension, props<{ dimension: Array<Dimension>, pagination: PaginationMetaData }>());
export const errorGetDimension = createAction(DimensionActionsTypes.errorGetDimension, props<{ payload: any }>());

export const createDimension = createAction(DimensionActionsTypes.createDimension, props<{ dimension: Dimension }>());
export const successCreateDimension = createAction(DimensionActionsTypes.successCreateDimension, props<{ dimension: Dimension }>());
export const errorCreateDimension = createAction(DimensionActionsTypes.errorCreateDimension, props<{ payload: any }>());

export const updateDimension = createAction(DimensionActionsTypes.updateDimension, props<{ dimension: Dimension, id: string }>());
export const successUpdateDimension = createAction(DimensionActionsTypes.successUpdateDimension, props<{ dimension: Dimension }>());
export const errorUpdateDimension = createAction(DimensionActionsTypes.errorUpdateDimension, props<{ payload: any }>());